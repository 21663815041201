import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { FreshbooksService } from "../../../../../services/freshbooks.service";
import { PricingService } from "../../../../../services/pricing.service";
import { OrganizationService } from "../../../../../services/organization.service";
import { EventService } from "../../../../../services/event.service";
import { S25Util } from "../../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { jSith } from "../../../../../util/jquery-replacement";
import { S25Const } from "../../../../../util/s25-const";
import { S25LoadingApi } from "../../../../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-modal-invoice-freshbooks")
@Component({
    selector: "s25-ng-modal-invoice-freshbooks",
    template: `@if (init) {
        <div>
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            @if (initModal) {
                <div>
                    @if (!noBillingContacts && !relatedButNotInSet) {
                        <div class="S25InvoiceOrg">
                            @if (data.invoiceWarning) {
                                <div class="orgSummary">
                                    <span class="ngRed ngBold">{{ data.invoiceWarning }}</span>
                                </div>
                            }
                            <div class="orgSummary">
                                <table>
                                    <tr>
                                        <th>Organization:</th>
                                        <td>{{ data.orgName }}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="invoice_contact" class="ngBold">Contact:</label>
                                        </th>
                                        <td>
                                            @if (billingContacts.length > 1) {
                                                <select
                                                    [(ngModel)]="data.selectedContact"
                                                    class="cn-form__control"
                                                    id="invoice_contact"
                                                >
                                                    @for (contact of billingContacts; track contact) {
                                                        <option [ngValue]="contact">
                                                            {{ contact.contact_name }}
                                                        </option>
                                                    }
                                                </select>
                                            }
                                            @if (billingContacts.length === 1) {
                                                <span>{{
                                                    data.selectedContact.contact_name +
                                                        " (" +
                                                        data.selectedContact.contact_email +
                                                        ")"
                                                }}</span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Total:</th>
                                        <td>{{ data.invoicesByEvent.totalOrgCharge | currency }}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="invoiceProvider">
                                <div class="invoiceProviderHeader">
                                    <b>Invoice with 25Live Accounting</b>
                                </div>
                                <div class="invoiceProviderBody">
                                    <table>
                                        <tr>
                                            <th>
                                                <label for="invoice_instance" class="ngBold">Instance:</label>
                                            </th>
                                            <td style="text-align: initial;">
                                                @if (instances.length > 1) {
                                                    <select
                                                        [(ngModel)]="data.selectedInstance"
                                                        id="invoice_instance"
                                                        class="cn-form__control"
                                                    >
                                                        @for (instance of instances; track instance) {
                                                            <option [ngValue]="instance">
                                                                {{ instance.instance_name }}
                                                            </option>
                                                        }
                                                    </select>
                                                }
                                                @if (instances.length === 1) {
                                                    <span>{{ data.selectedInstance.instance_name }}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <label for="invoice_terms" class="ngBold">Terms:</label>
                                            </th>
                                            <td>
                                                <input
                                                    type="text"
                                                    id="invoice_terms"
                                                    class="c-input"
                                                    [(ngModel)]="data.freshbooksTerms"
                                                    style="width: 300px;"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <label for="invoice_notes" class="ngBold">Notes:</label>
                                            </th>
                                            <td>
                                                <textarea
                                                    id="invoice_notes"
                                                    class="c-input"
                                                    [(ngModel)]="data.freshbooksNotes"
                                                    style="height: 100px; width: 300px;"
                                                ></textarea>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    @if (noBillingContacts) {
                        <div>
                            <div class="orgSummary">
                                <div style="color: #F00">
                                    <span
                                        >A <b>Billing Contact</b> with a valid email address is required to invoice an
                                        organization</span
                                    >
                                </div>
                                <div style="margin-top: 16px;">
                                    <span
                                        >Please contact your 25Live administrator and ask them to assign a valid Billing
                                        Contact to the {{ data.orgName }} organization.</span
                                    >
                                </div>
                            </div>
                            @if (relatedButNotInSet) {
                                <div>
                                    <div class="orgSummary">
                                        <span
                                            >This event is not a member of the same set as its other related events.
                                            Please contact your administrator to fix this.</span
                                        >
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <s25-ng-modal-footer [content]="s25ModalFooter"></s25-ng-modal-footer>
                    <ng-template #s25ModalFooter>
                        <div class="footerContainer">
                            @if (data.showCreateBtn) {
                                <button
                                    class="aw-button aw-button--primary"
                                    (click)="createInvoice()"
                                    [disabled]="disableSaveButton"
                                >
                                    {{ this.disableSaveButton ? "" : "Create / Update Invoice" }}
                                    <s25-loading-inline [model]="{ text: 'Creating Invoice...' }"></s25-loading-inline>
                                </button>
                            }
                        </div>
                    </ng-template>
                </div>
            }
        </div>
    }`,
    styles: `
        .invoiceProviderHeader {
            text-align: initial;
        }

        :host ::ng-deep button .c-loading__inner {
            stroke: #fff;
        }

        :host ::ng-deep button .s25-text {
            color: #fff !important;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ModalInvoiceFreshbooksComponent implements OnInit, OnDestroy {
    @Input() data: any;
    @Input() invoiceLang: any;

    @Output() updateInvoiceData: EventEmitter<void> = new EventEmitter<void>();

    init: boolean;
    initModal: boolean;
    newLine: string = String.fromCharCode(10);
    relatedButNotInSet: boolean;
    billingContacts: any = [];
    noBillingContacts: boolean;
    instances: any;
    showCreateBtn: boolean;
    disableSaveButton: boolean;
    defaultLockErrorMsg: string =
        "There was an error locking the event/set invoice(s). Please contact your administrator.";

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
        S25LoadingApi.init(this.elementRef.nativeElement);

        try {
            const [invoicesData, pricingEvents, org, instances, eventSet] = await Promise.all([
                FreshbooksService.getS25Invoices(this.data.eventId, this.data.billId),
                this.data.billId
                    ? PricingService.getPricingAndEventsFromPricingSetId(this.data.eventId, this.data.billId)
                    : PricingService.getPricingRelatedEvents(this.data.eventId, this.data.isSetMode),
                OrganizationService.getOrganizationById(this.data.orgId, ["contacts"]),
                FreshbooksService.getInstances(),
                !this.data.billId && EventService.getSet(this.data.eventId),
            ]);

            this.data.orgName = S25Util.propertyGet(org, "organization_name") ?? "";
            this.data.orgNum = S25Util.propertyGet(org, "organization_number") ?? "";

            //sets invoiceId, warning, invoice data model, and freshbooks notes
            await this.setDynamicContent(
                invoicesData,
                pricingEvents,
                this.data.orgId,
                this.data.isSetMode,
                this.newLine,
                false,
            );
            this.data.setId = S25Util.propertyGet(eventSet, "set_id") ?? null;

            //lock by event id and set id
            const lockData = await FreshbooksService.getInvoiceLock(this.data.eventId, this.data.billId);

            if (parseInt(lockData?.root?.resp) === 1) {
                this.data.invoiceLockIds = lockData.root.invoice_lock_id;

                //if there is not set id for this event and we are in set mode... something bad has happened...
                //basically, it means we have a related event that is NOT in the set, meaning something is NOT obeying the set property
                if (!this.data.setId && this.data.isSetMode) {
                    this.relatedButNotInSet = true;
                } else {
                    //get billing contacts
                    jSith.forEach(S25Util.propertyGet(org, "contact"), (_, contact) => {
                        //-1 is a billing contact in organizations
                        if (
                            parseInt(contact.contact_role_id) === S25Const.billingRole.organization &&
                            contact.contact_email
                        ) {
                            this.billingContacts.push(contact);
                        }
                    });
                    this.billingContacts.sort(S25Util.shallowSort("contact_name"));
                    this.data.selectedContact = this.billingContacts[0];
                    this.noBillingContacts = this.billingContacts.length === 0;

                    //get freshbooks instances
                    this.instances = S25Util.propertyGet(instances, "instance") ?? [];
                    this.data.selectedInstance = this.instances[0];

                    this.data.showCreateBtn = true;
                }

                this.initModal = true;
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                this.cd.detectChanges();
            } else if (lockData?.root) {
                alert(lockData.root.msg || this.defaultLockErrorMsg);
            } else {
                alert(this.defaultLockErrorMsg);
            }
        } catch (error) {
            S25Util.showError(error);
        }
    }

    async deleteLock() {
        return await FreshbooksService.deleteInvoiceLock(this.data.invoiceLockIds);
    }

    async clientError(err: any) {
        S25LoadingApi.destroy(this.elementRef.nativeElement.querySelector(".aw-button.aw-button--primary"));
        this.disableSaveButton = false;
        this.cd.detectChanges();

        await this.genericSaveError(
            err,
            "Failed to create or find client. Please verify the WebServices Freshbooks configuration.",
        );
    }

    async genericSaveError(err: any, msg: string) {
        S25Util.showError(err, msg);

        S25LoadingApi.destroy(this.elementRef.nativeElement.querySelector(".aw-button.aw-button--primary"));
        this.disableSaveButton = false;
        this.cd.detectChanges();

        await this.deleteLock();
    }

    freshbooksNotes(invoicesByEvent: any, newLine: string) {
        let freshBooksNotes = this.data.orgNum ? `Account Number: ${this.data.orgNum}${newLine}` : "";
        let isAdjustmentsHeader = true;

        jSith.forEach(invoicesByEvent.events, (_, event) => {
            freshBooksNotes += `${event.eventName} (${event.eventLocator}) `;
            freshBooksNotes += S25Util.date.equalDate(event.eventStartDt, event.eventEndDt)
                ? event.eventStartDt
                : `${event.eventStartDt} - ${event.eventEndDt}${newLine}`;
            isAdjustmentsHeader = true;

            jSith.forEach(event.profile, (_, profile) => {
                jSith.forEach(profile.billingItems, (_, item) => {
                    if (item.adjustment_amt?.length || item.adjustment_percent?.length) {
                        const isAdjAmount = item.adjustment_amt?.length;
                        if (isAdjustmentsHeader) {
                            freshBooksNotes += `Includes adjustments to the following items:${newLine}`;
                            isAdjustmentsHeader = false;
                        }

                        freshBooksNotes += `${item.bill_item_name}: `;
                        freshBooksNotes += isAdjAmount
                            ? PricingService.formatCurrency(item.adjustment_amt)
                            : `${item.adjustment_percent * 100}% (${PricingService.formatCurrency(
                                  PricingService.itemAdjustmentPercToAmt(item),
                              )})`;
                        freshBooksNotes += item.adjustment_name ? `, ${item.adjustment_name}` : "" + newLine;
                    }
                });
            });
        });
        return freshBooksNotes;
    }

    //create warning about updating existing invoice -- also set invoice id that we'd later update
    warning(invoicesData: any, isSetMode: boolean) {
        let warning = "";
        const findOrg = (obj: any) => obj.organization_id === this.data.orgId;

        if (invoicesData?.invoice) {
            const eventInvoices = invoicesData.invoice.eventInvoices || invoicesData.invoice.customBillInvoices || [];
            const eventOrgInvoice = eventInvoices?.find(findOrg);

            const setInvoices = invoicesData.invoice.setInvoices ?? [];
            const setOrgInvoice = setInvoices?.find(findOrg);

            if (isSetMode && setOrgInvoice) {
                warning = "An invoice already exists on this event set and organization. Continuing will overwrite it.";
                this.data.invoiceId = setOrgInvoice.invoice_id;
            } else if (!isSetMode && eventOrgInvoice) {
                warning = "An invoice already exists on this event and organization. Continuing will overwrite it.";
                this.data.invoiceId = eventOrgInvoice.invoice_id;
            }
        }

        return warning;
    }

    async setDynamicContent(
        invoicesData: any,
        pricingEvents: any,
        orgId: number,
        isSetMode: boolean,
        newLine: string,
        refetchData: boolean,
    ) {
        if (refetchData) {
            const [newInvoicesData, newPricingEvents] = await Promise.all([
                FreshbooksService.getS25Invoices(this.data.eventId, this.data.billId),
                this.data.billId
                    ? PricingService.getPricingAndEventsFromPricingSetId(this.data.eventId, this.data.billId)
                    : PricingService.getPricingRelatedEvents(this.data.eventId, isSetMode),
            ]);
            invoicesData = newInvoicesData;
            pricingEvents = newPricingEvents;
        }

        //set warning for existing invoice (piggy back on getting any invoiceId as well)
        this.data.invoiceWarning = this.warning(invoicesData, isSetMode);
        this.data.invoicesByEvent = PricingService.formInvoiceModelFromPricing(pricingEvents, orgId);
        this.data.freshbooksNotes = this.freshbooksNotes(this.data.invoicesByEvent, newLine);
    }

    async close() {
        // await this.deleteLock();
        this.updateInvoiceData.emit();
    }

    validate() {
        if (this.data.selectedContact?.contact_email) return true;

        S25LoadingApi.destroy(this.elementRef.nativeElement.querySelector(".aw-button.aw-button--primary"));
        this.disableSaveButton = false;
        this.cd.detectChanges();

        alert("Failed to find email address for billing contact");
        return false;
    }

    async getClient() {
        return await FreshbooksService.getClientByOrg(
            this.data.selectedContact.contact_email,
            this.data.selectedInstance.instance_id,
            this.data.orgName,
        );
    }

    async createInvoice() {
        S25LoadingApi.init(this.elementRef.nativeElement.querySelector(".aw-button.aw-button--primary"));
        this.disableSaveButton = true;
        this.cd.detectChanges();

        if (this.validate()) {
            let clientId;
            try {
                clientId =
                    (await this.getClient()) ??
                    (
                        await FreshbooksService.createClient(
                            this.data.selectedContact.contact_id,
                            this.data.orgName,
                            this.data.selectedInstance.instance_id,
                        )
                    )?.client_id;
            } catch (e) {
                S25LoadingApi.destroy(this.elementRef.nativeElement.querySelector(".aw-button.aw-button--primary"));
                this.disableSaveButton = false;
                this.cd.detectChanges();
            }

            if (clientId) {
                try {
                    const lockData = await FreshbooksService.getInvoiceLock(this.data.eventId, this.data.billId);

                    if (parseInt(lockData?.root?.resp) === 1) {
                        this.data.invoiceLockIds = lockData.root.invoice_lock_id;

                        const data = await FreshbooksService.createOrOverwriteInvoice(
                            this.data.invoicesByEvent,
                            clientId,
                            this.data.freshbooksNotes,
                            this.data.freshbooksTerms,
                            this.data.selectedInstance.instance_id,
                            this.data.invoiceId,
                        );

                        const invoiceId = S25Util.propertyGetVal(data, "invoice_id") ?? this.data.invoiceId; //either invoice id of NEW invoice or invoice of UPDATED one (eg passed in)

                        if (invoiceId) {
                            try {
                                if (this.data.isSetMode) {
                                    await FreshbooksService.setS25InvoiceOnSet(
                                        this.data.setId,
                                        this.data.orgId,
                                        invoiceId,
                                        this.data.selectedInstance.instance_id,
                                    );
                                } else if (!this.data.billId) {
                                    await FreshbooksService.setS25InvoiceOnEvent(
                                        this.data.eventId,
                                        this.data.orgId,
                                        invoiceId,
                                        this.data.selectedInstance.instance_id,
                                    );
                                } else {
                                    await FreshbooksService.setS25InvoiceOnBill(
                                        this.data.billId,
                                        this.data.orgId,
                                        invoiceId,
                                        this.data.selectedInstance.instance_id,
                                    );
                                }
                                return await this.close();
                            } catch (error) {
                                S25LoadingApi.destroy(
                                    this.elementRef.nativeElement.querySelector(".aw-button.aw-button--primary"),
                                );
                                this.disableSaveButton = false;
                                this.cd.detectChanges();

                                S25Util.showError(error);
                            }
                        } else {
                            await this.genericSaveError(null, "Failed to get invoice ID from invoice.create response");
                        }
                    } else if (lockData?.root) {
                        await this.genericSaveError(null, lockData.root.msg ?? this.defaultLockErrorMsg);
                    } else {
                        await this.genericSaveError(null, this.defaultLockErrorMsg);
                    }
                } catch (error) {
                    await this.clientError(error);
                }
            }
        }
    }

    async ngOnDestroy() {
        await this.deleteLock();
    }
}
