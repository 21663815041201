import { NgModule } from "@angular/core";
import { S25PreferenceToggleComponent } from "./s25.preference.toggle.component";
import { S25EditableBooleanModule } from "../s25-editable/s25-editable-boolean/s25.editable.boolean.module";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [CommonModule, S25EditableBooleanModule],
    exports: [S25PreferenceToggleComponent],
    declarations: [S25PreferenceToggleComponent],
    providers: [S25PreferenceToggleComponent],
})
export class S25PreferenceToggleModule {
    constructor() {}
}
