import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PreferenceService } from "../../services/preference.service";
import { EditableBooleanModelI } from "../s25-editable/s25-editable-boolean/s25.editable.boolean.component";

@TypeManagerDecorator("s25-ng-preference-toggle")
@Component({
    selector: "s25-ng-preference-toggle",
    template: `
        <div>
            @if (label) {
                <label>{{ label }}</label>
            }
            @if (!!toggleBean) {
                <s25-editable-boolean
                    [model]="toggleBean"
                    (modelValueChange)="onModelChange($event)"
                ></s25-editable-boolean>
            }
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PreferenceToggleComponent implements OnInit {
    @Input() prefName: string;
    @Input() prefValue?: boolean = undefined;
    @Input() prefType?: string = "U";
    @Input() label?: string = "Edit Mode";
    @Input() trueValue?: string = "T";
    @Input() falseValue?: string = "F";
    @Input() trueLabel?: string = "On";
    @Input() falseLabel?: string = "Off";
    @Input() onChange?: () => void; // ! Use @Output in TS components
    @Output() modelValueChange = new EventEmitter<boolean>();

    toggleBean: EditableBooleanModelI;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        if (S25Util.isUndefined(this.prefValue)) {
            PreferenceService.getPreferences([this.prefName]).then((prefObj) => {
                this.prefValue = prefObj[this.prefName]?.value === this.trueValue;
                this.modelValueChange.emit(this.prefValue); // Emit here to pass initial value up to parents
                this.setToggleBean();
            });
        } else {
            this.setToggleBean();
        }
    }

    setToggleBean() {
        this.toggleBean = {
            data: this.prefValue,
            trueLabel: this.trueLabel ?? "Yes",
            falseLabel: this.falseLabel ?? "No",
            toggleLabel: this.label,
        };
        this.changeDetector.detectChanges();
    }

    onModelChange(data: boolean) {
        let daoVal: boolean | string = data;
        if (S25Util.isDefined(this.trueValue) && data) {
            daoVal = this.trueValue;
        } else if (S25Util.isDefined(this.falseValue) && data) {
            daoVal = this.falseValue;
        }

        return PreferenceService.setPreference(this.prefName, daoVal, this.prefType).then(() => {
            this.prefValue = data;
            setTimeout(() => {
                this.onChange?.();
                this.modelValueChange.emit(this.prefValue);
            });
        });
    }
}
